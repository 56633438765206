import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@app/configs/api.config";
import { ApiResponse } from "@app/models/common.models";
import { PageableEventsShortInfo } from "@app/models/event.models";
import { Event } from "@app/store/event/model";
import {
  Pageable,
  PageableQuery,
  PageableResponse,
} from "@tellsy/common/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  ActivityTypeDto,
  Moderator,
  ModeratorLogo,
  Tariff, TariffType
} from "../models/moderator-model";

@Injectable({ providedIn: "root" })
export class ModeratorService {
  constructor(private http: HttpClient) {}

  diagnostics<T = any>(json: T) {
    return this.http.post<T>(API.moderator.diagnostics, json);
  }

  loadModerators(pageable: Pageable, search = "") {
    return this.http.get<PageableResponse<Moderator>>(
      API.moderator.loadModerators,
      {
        params: {
          ...PageableQuery.create(pageable),
          search,
        },
      },
    );
  }

  getModerator(): Observable<Moderator> {
    return this.http.get<Moderator>(API.moderator.getModerator());
  }

  getAllActivityTypes(): Observable<ActivityTypeDto[]> {
    return this.http.get<ActivityTypeDto[]>(API.events.getAllActivityTypes());
  }

  changeUsername(username: string): Observable<{ username: string }> {
    return this.http.post<{ username: string }>(API.auth.changeUsername(), {
      username,
    });
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(API.auth.changePassword(), {
      oldPassword,
      newPassword,
    });
  }

  setCompanyName(companyName: string): Observable<Moderator> {
    return this.http.post<Moderator>(API.moderator.setCompanyName(), {
      companyName,
    });
  }

  setLogo(logo: File): Observable<{ logo: ModeratorLogo }> {
    const formData: FormData = new FormData();
    formData.append("file", logo, logo.name);

    return this.http.post<{ logo: ModeratorLogo }>(
      API.moderator.setLogo(),
      formData,
    );
  }

  deleteLogo(): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(API.moderator.deleteLogo());
  }

  changeLicenseExpiration(
    moderatorId: string,
    licenseStart: string,
    licenseExpiration: string,
  ): Observable<{ licenseStart: string; licenseExpiration: string }> {
    return this.http
      .post<Moderator>(API.moderator.setSubscriptionExpiration(moderatorId), {
        startDate: licenseStart,
        endDate: licenseExpiration,
      })
      .pipe(
        map((moderator) => ({
          licenseStart: moderator.license.start,
          licenseExpiration: moderator.license.expiration,
        })),
      );
  }

  changeModeratorTariff(moderatorId: string, tariff: string) {
    return this.http.post<ApiResponse>(
      API.moderator.changeModeratorTariff(moderatorId),
      { tariffName: tariff },
    );
  }

  getModeratorCustomTariff(moderatorId: string, tariff: TariffType) {
    return this.http.get<Tariff>(
      API.moderator.getModeratorCustomTariff(moderatorId, tariff),
    );
  }

  changeModeratorCustomTariff(moderatorId: string, tariff: Tariff) {
    return this.http.put<ApiResponse>(
      API.moderator.updateModeratorCustomTariff(moderatorId),
      tariff,
    );
  }

  grantAccess({ moderatorId, eventIds }) {
    return this.http.post<ApiResponse>(
      API.events.grantAccess(moderatorId),
      eventIds,
    );
  }

  revokeAccess({ moderatorId, eventIds }) {
    return this.http.post<ApiResponse>(
      API.events.revokeAccess(moderatorId),
      eventIds,
    );
  }

  setNewOwner({ moderatorId, eventId }) {
    return this.http.post<ApiResponse>(
      API.events.setNewOwner(moderatorId),
      eventId,
    );
  }

  loadEventsToGrantAccess(moderatorId: string, pageNumber: number) {
    return this.http.get<PageableEventsShortInfo>(
      API.events.loadEventsToGrantAccess(moderatorId),
      {
        params: {
          page: pageNumber,
        },
      },
    );
  }

  loadGrantedAccessEvents(moderatorId: string) {
    return this.http.get<Event[]>(
      API.events.loadGrantedAccessEvents(moderatorId),
    );
  }
}
