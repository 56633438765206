import { Word } from "d3-cloud";

export class TagDto {
  word: string;
  participantIds: string[] = [];

  static toTag(dto: TagDto): Tag {
    return {
      text: dto.word,
      count: dto.participantIds.length,
    };
  }
}

export class TagCloudDto {
  id: string;
  eventId: string;
  tags: TagDto[] = [];
  instructionId: string;
  instruction: TagCloudInstruction;
  settingsId: string;
  settings: TagCloudSettings;
}

export class TagCloudInstruction {
  tagCloudId?: string;
  instructionText: string;
}

export enum TagCloudFontWeight {
  LIGHTEST = "100",
  LIGHTER = "300",
  NORMAL = "normal",
  BOLDER = "500",
  BOLD = "bold",
}

export enum SpiralType {
  ARCHIMEDEAN = "archimedean",
  RECTANGULAR = "rectangular",
}
export type TagsSource = "participants" | "text";

export class TagCloudSettings {
  tagCloudId?: string;
  padding = 1;
  width = 100;
  height = 100;
  minFontSize = 20;
  maxFontSize = 80;
  orientations = 5;
  skewFrom = -30;
  skewTo = 30;
  fontFace = "Roboto";
  fontWeight = TagCloudFontWeight.NORMAL;
  spiralType = SpiralType.ARCHIMEDEAN;
  colorTheme = ["#4629E2", "#A682FF", "#00C3E4", "#F98484", "#F5C309"];
  tagsSource = "participants";
  text = "";

  constructor(settings?: Partial<TagCloudSettings>) {
    if (settings) {
      Object.keys(settings).forEach((key) => (this[key] = settings[key]));
    }
  }
}

export class TagsData {
  tags: Tag[];
  allTagsVotesCount = 0;
  maxCount = 0;

  constructor(tags: Tag[]) {
    this.tags = tags;
    tags.forEach((tag) => {
      this.allTagsVotesCount += tag.count;
      if (tag.count > this.maxCount) {
        this.maxCount = tag.count;
      }
    });
  }

  static fromTagDtos(dtos: TagDto[]) {
    return new TagsData(dtos.map((el) => TagDto.toTag(el)));
  }

  static fromString(str: string) {
    return new TagsData(Tag.fromString(str));
  }
}

export class Tag {
  text: string;
  count: number;

  static fromString(str: string): Tag[] {
    if (!str) {
      return [];
    }

    const wordToTagMap: Map<string, Tag> = new Map();

    str
      .trim()
      .split(" ")
      .forEach((word) => {
        if (wordToTagMap.has(word)) {
          const tag = wordToTagMap.get(word);
          wordToTagMap.set(word, { ...tag, count: tag.count + 1 });
        } else {
          wordToTagMap.set(word, { text: word, count: 1 });
        }
      });

    return [...wordToTagMap.values()];
  }
}

export interface TagWord extends Word {
  text: string;
  rotate: number | undefined;
  size: number;
}

export interface TagWordSettings {
  "fill": string | undefined;
  "font-family": string;
  "font-size": string;
  "font-weight": string;
  "text-anchor": "middle";
  "transform": string;
}

// event-service response
// { [activityId]: TagCloudDataForReport }
export interface TagCloudDataForReport {
  eventId: string;
  tagsData: TagsData;
  settings: TagCloudSettings;
}

// report-service request
// imagesByEvent: EventTagCloudImages[];
export interface EventTagCloudImages {
  eventId: string;
  activityIdToImageMap: Record<string, string>;
}
